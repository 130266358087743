import {LogLevel} from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: '6e2e3860-ff8c-4060-bc1e-12c0e4ed144a',
        authority: 'https://login.microsoftonline.com/1e968b2f-2165-4a81-b5bf-95ffd9d9d517',
        redirectUri: process.env.REACT_APP_URL + '/login',
    },
    scopes: ['openid', 'profile', 'email'],
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    default:
                        return;
                }
            },
            logLevel: LogLevel.Info,
        },
    },
};
