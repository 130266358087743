import React, {useEffect} from 'react';
import withRouter from '../../components/Common/withRouter';
import {useNavigate} from "react-router-dom";

const Logout: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.clear();
        sessionStorage.clear();
        navigate('/');
    }, []);

    return (
        <></>
    );
};

export default withRouter(
    Logout
);

