import React, { FC, useEffect, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const ProfileMenu: FC = () => {
  const [menu, setMenu] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('...');
  const [avatar, setAvatar] = useState<string>('');
  const { t } = useTranslation();

  useEffect((): void => {
    setUsernameFromLocalStorage();
  }, []);

  const setUsernameFromLocalStorage = (): void => {
    const obj = JSON.parse(sessionStorage.getItem('user') || '{}');
    setUsername(obj?.name ?? '...');
  };

  useEffect((): void => {
    if (sessionStorage.getItem('avatarUrl')) {
      setAvatar(sessionStorage.getItem('avatarUrl') ?? '');
      return;
    }

    fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then(response => response.blob())
      .then(blob => {
        const avatarUrl = URL.createObjectURL(blob);

        sessionStorage.setItem('avatarUrl', avatarUrl);
        setAvatar(avatarUrl);
      })
      .catch(error => {
        console.error('Error fetching user avatar:', error);
      });
  }, []);

  return (
    <Dropdown isOpen={menu} toggle={() => setMenu(prevState => !prevState)} className="d-inline-block user-dropdown">
      <DropdownToggle tag="button" className="btn header-item waves-effect" id="page-header-user-dropdown">
        {avatar !== '' && <img className="rounded-circle header-profile-user me-1" src={avatar} alt="" />}
        <span className="d-none d-xl-inline-block ms-1 text-transform">{username}</span>
        <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem className="text-danger" href="/logout">
          <i className="ri-shut-down-line align-middle me-1 text-danger"></i> {t('Logout')}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
export default ProfileMenu;
