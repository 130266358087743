import React, {Component} from 'react';
import {Route, Routes} from 'react-router-dom';
import {authProtectedRoutes, publicRoutes} from './routes';
import VerticalLayout from './components/VerticalLayout/';
import NonAuthLayout from './components/NonAuthLayout';
import './assets/scss/theme.scss';
import AppRoute from './routes/route';
import {connect} from 'react-redux';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
        <React.Fragment>
          <Routes>
            {publicRoutes.map((route, idx) => (
                <Route
                    path={route.path}
                    element={
                      <NonAuthLayout>
                        {route.component}
                      </NonAuthLayout>
                    }
                    key={idx}
                    exact={true}
                />
            ))}

            {authProtectedRoutes.map((route, idx) => (
                <Route
                    path={route.path}
                    element={
                      <AppRoute>
                        <VerticalLayout>{route.component}</VerticalLayout>
                      </AppRoute>}
                    key={idx}
                    exact={true}
                />
            ))}
          </Routes>
        </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
