import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import {PublicClientApplication} from '@azure/msal-browser';
import {msalConfig} from './config/msalConfig';
import {MsalProvider} from '@azure/msal-react';

const root = ReactDOM.createRoot(document.getElementById("root"));
const pca = new PublicClientApplication(msalConfig);


root.render (
    <Provider store={store}>
      <MsalProvider instance={pca}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
      </MsalProvider>
    </Provider>
);

serviceWorker.unregister();
