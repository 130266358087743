import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const axiosApi = axios.create({
    baseURL: API_URL,
    headers: {
        Accept: 'application/json',
    },
});
