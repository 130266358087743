import React, { useEffect } from 'react';
import { Alert, Col, Container, Row } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import logodark from '../../assets/images/sharebee_logo.svg';
import logolight from '../../assets/images/sharebee_logo_white.svg';
import msButton from '../../assets/images/ms-symbollockup_signin_light.svg';
import withRouter from '../../components/Common/withRouter';

interface LoginProps {
  loginError: string;
  router: any;
}

const Login: React.FC<LoginProps> = (props: LoginProps) => {
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add('auth-body-bg');

    return () => {
      document.body.classList.remove('auth-body-bg');
    };
  }, []);

  const handleSubmit = async (): Promise<void> => {
    try {
      await instance.loginRedirect();
    } catch (error) {
      console.error('Błąd logowania:', error);
    }
  };

  useEffect(() => {
    if (accounts.length > 0) {
      const accessTokenRequest = {
        scopes: ['openid', 'profile'],
        account: accounts[0],
      };

      instance
        .acquireTokenSilent(accessTokenRequest)
        .then(response => {
          const idTokenClaims = response.idTokenClaims;
          sessionStorage.setItem('user', JSON.stringify(idTokenClaims));
          sessionStorage.setItem('token', response.accessToken);

          navigate('/');
        })
        .catch(error => {
          console.error('Error acquiring token silently:', error);
        });
    }
  }, [accounts, instance]);

  return (
    <div>
      <Container fluid className="p-0">
        <Row className="g-0">
          <Col lg={4}>
            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div className="w-100">
                <Row className="justify-content-center">
                  <Col lg={9}>
                    <div>
                      <div className="text-center">
                        <div>
                          <Link to="/" className="">
                            <img src={logodark} alt="" height="20" className="auth-logo logo-dark mx-auto" />
                            <img src={logolight} alt="" height="20" className="auth-logo logo-light mx-auto" />
                          </Link>
                        </div>

                        <h4 className="font-size-18 mt-4">Dzień dobry!</h4>
                        <p className="text-muted">Autoryzuj się kontem Microsoft, aby przejść do platformy.</p>
                      </div>
                      {props.loginError && props.loginError ? <Alert color="danger">{props.loginError}</Alert> : null}
                      <div className="p-2 mt-5 text-center">
                        <a onClick={handleSubmit} style={{ cursor: 'pointer' }}>
                          <img alt={'Login by Microsoft'} src={msButton} />
                        </a>
                      </div>
                      <div className="mt-5 text-center">
                        <p>{new Date().getFullYear()} © Sharebee.</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col lg={8}>
            <div className="authentication-bg">
              <div className="bg-overlay"></div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(Login);
