import React, { FC } from 'react';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

interface HumanizedDateProps {
  date: string;
}

dayjs.extend(localizedFormat);

const HumanizedDate: FC<HumanizedDateProps> = ({ date }) => {
  const humanizedDate: string = dayjs(date).locale('pl').format('LLL');

  return <span>{humanizedDate}</span>;
};
export default HumanizedDate;
