import React from 'react';
import { Navigate } from 'react-router-dom';

// Authentication related pages
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import Dashboard from '../pages/Dashboard';
import InstanceList from '../pages/Instance/List';
import NPSView from '../pages/Report/NPS';
import WebinarRegistrationView from '../pages/Report/WebinarRegistration';

const authProtectedRoutes = [
  { path: '/instances', component: <InstanceList /> },
  { path: '/report/nps', component: <NPSView /> },
  { path: '/report/webinar-registrations', component: <WebinarRegistrationView /> },
  { path: '/dashboard', component: <Dashboard /> },
  { path: '/', exact: true, component: <Navigate to="/instances" /> },
];

const publicRoutes = [
  { path: '/logout', component: <Logout /> },
  { path: '/login', component: <Login /> },
];

export { authProtectedRoutes, publicRoutes };
