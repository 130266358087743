import React, { FC, useEffect, useState } from 'react';
import { Badge, Col, Input, Label, Row } from 'reactstrap';
import { axiosApi } from '../../helpers/Axios';
import 'toastr/build/toastr.min.css';
import 'dayjs/locale/pl';
import HumanizedDate from '../Date/HumanizedDate';

interface Webinar {
  id: number | null;
  uuid: string | null;
  title: string;
  publishedAt: string;
}

interface InstanceSelectorProps {
  onChange: (webinar: string) => void;
}

const WebinarSelector: FC<InstanceSelectorProps> = ({ onChange }) => {
  const [webinars, setWebinars] = useState<Webinar[]>([]);
  const [selectedWebinar, setSelectedWebinar] = useState<string>('');

  useEffect(() => {
    axiosApi
      .get('webinars', {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.token,
        },
      })
      .then(result => setWebinars(result.data.data));
  }, []);

  useEffect(() => {
    onChange(selectedWebinar);
  }, [selectedWebinar]);

  return (
    <>
      <Row>
        <Col lg="12" className={'mb-2'}>
          <h3>Wybierz webinar</h3>
        </Col>
      </Row>
      <Row>
        <Col lg="12" className={'mb-2'}>
          <select
            id="custom-select"
            className="form-select"
            value={selectedWebinar}
            onChange={e => {
              setSelectedWebinar(e.target.value || '');
            }}
          >
            {webinars.map(option => (
              <option key={option.uuid} value={option.uuid || ''}>
                {option.title} (Opublikowano: <HumanizedDate date={option.publishedAt} />)
              </option>
            ))}
          </select>
        </Col>
      </Row>
    </>
  );
};
export default WebinarSelector;
