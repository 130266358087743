import React from 'react';
import {Navigate} from 'react-router-dom';
import {useIsAuthenticated, useMsal} from '@azure/msal-react';
import {InteractionStatus} from '@azure/msal-browser';

const AppRoute = (props) => {
  const isAuthenticated = useIsAuthenticated();
  const {inProgress} = useMsal();

  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    return (
        <Navigate to={{pathname: '/login', state: {from: props.location}}}/>
    );
  }
  return (<React.Fragment>
    {props.children}
  </React.Fragment>);
};

export default AppRoute;

